import { Controller } from 'stimulus'

import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default class extends Controller {
  static targets = ['prev', 'next', 'container']

  connect() {
    new Swiper(this.containerTarget, {
      slidesPerView: 1,
      spaceBetween: 30,
      autoplay: true,
      loop: true,
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
      breakpoints: {
        1024: {
          slidesPerView: 2.5,
        },
        1280: {
          slidesPerView: 3,
        },
      },
    })
  }
}
