import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['panel', 'panelContent']

  active(event) {
    const ele = event.currentTarget
    const panel = ele.closest('[data-accordion-item]')
    const panelActive = panel.getAttribute('data-accordion-active')

    if (this.single == 'true') {
      for (const panel of this.panelTargets) {
        panel.setAttribute('data-accordion-active', false)
      }
    }

    if (panelActive == 'false') {
      panel.setAttribute('data-accordion-active', true)
    } else {
      panel.setAttribute('data-accordion-active', false)
    }
  }

  get single() {
    return this.data.get('single')
  }
}
