require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()

require('css/application.scss')

import 'typeface-biryani'
import 'typeface-rufina'
import 'controllers'
import 'alpinejs'

require.context('../images', true)
